import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
 
  // 首页
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/home/home.vue')
  },
  // 产品服务
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '@/views/product/product.vue')
  },
  // 新闻资讯
  {
    path: '/information',
    name: 'information',
    component: () => import(/* webpackChunkName: "about" */ '@/views/information/information.vue')
  },
  // 新闻资讯详情
  {
    path:'/NewsDetail',
    name: 'NewsDetail',
    //也不使用懒加载，先在文件头部提前引入
    component: () => import(/* webpackChunkName: "about" */ '@/views/NewsDetail/NewsDetail.vue'),
  },
  // 融合创新
  {
    path: '/Integration',
    name: 'Integration',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Integration/Integration.vue')
  },
  // 人才招聘
  {
    path: '/recruitment',
    name: 'recruitment',
    component: () => import(/* webpackChunkName: "about" */ '@/views/recruitment/recruitment.vue')
  },
  // 团队建设
  {
    path: '/teamBuilding',
    name: 'teamBuilding',
    component: () => import(/* webpackChunkName: "about" */ '@/views/teamBuilding/teamBuilding.vue')
  },
  // 关于我们
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutUs/AboutUs.vue')
  },
  // 搜索
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '@/views/search/search.vue')
  },
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
