import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pagename: null, // head页面tab跳转
  },
  getters: {
  },
  mutations: {
    isshow(state, value) {
      state.pagename = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
