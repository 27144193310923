<template>
    <div class="footer">
        <div class="conter-box">
            <div class="top">
             <div class="bottom">
            <div class="name">Zhejiang Luda Technology Co., Ltd</div>
            <div class="name">Address: Chengnan Industrial Park, Heping Town, Changxing County, Huzhou City, Zhejiang Province</div>
            <div class="name">Phone：+86-572-6290111</div>
            <div class="name">Email： info@luda-tec.com</div>
            <div class="name">Filing No: Zhejiang ICP Preparation No. 2022026386</div>
        </div>
        <!-- <div class="code">
                        <img src="@/imgs/erweima.jpg" alt="">
                        <div class="Follow">关注我们</div>
                    </div>
        <div class="modular" >
                    <div class="item" v-for="item in goPageList" :key="item.id" style="cursor: pointer;" @click="modularbtn(item.id)">{{ item.name }}</div>
                </div> -->
                
             
            </div>
        </div>
    </div>
</template>

<script>
export default {
    comments: {

    },
    props: {

    },
    created() {

    },
    mounted() {
        if (this.goPageList != []) {
            this.treeSelect()
        }
    },
    data() {
        return {
            goPageList: [],
        }
    },
    methods: {
        // 一级菜单
        async treeSelect() {
            const res = await fnAxios({
                method: "get",
                url: "/pcApi/treeSelect",
                headers: {
                    "Content-Type": "application/json"
                },
            })
            if (res.data.code == 200) {
                this.goPageList = res.data.data;
                console.log(this.goPageList);
            }
        },
        modularbtn(id) {
            console.log(id);
            if (id == 1 || id == 2 || id == 16 || id == 3 || id == 4) {
                this.$router.push({
                    path: "/product",
                    query: {
                        id: 1,
                    }
                })
            } else if (id == 5) {
                // this.$router.push("/information")
                this.$router.push({
                    path: "/information",
                    query: {
                        id: id,
                    }
                })
            } else if (id == 6) {
                // this.$router.push("/Integration")
                this.$router.push({
                    path: "/Integration",
                    query: {
                        id: id,
                    }
                })
            } else if (id == 10 || id == 13 || id == 33 || id == 34 || id == 35 || id == 36) {
                this.$router.push("/AboutUs")
            } else if (id == 8 || id == 7) {
                this.$router.push({
                    path: "/recruitment",
                    query: {
                        id: 8,
                    }
                })
            } else if (id == 9) {
                this.$router.push({
                    path: "/teamBuilding",
                    query: {
                        id: id,
                    }
                })
            }
        },
    }
}
</script>

<style scoped lang="less">
.footer {
    // position: fixed;
    // left: 0;
    // bottom: 0;
    font-size: 14px;
    background: #ECF5FF;
    color: #666;
    // height: 367px;

 
    .top {
        display: flex;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 100%;
      
        .bottom{
            line-height: 18px;
            margin: 10px auto;
            .name{
              display: inline-block;
                margin-left: 20px;
            }
            // .name1{
            //     font-size: 24px;
            //     margin-bottom: 20px;
            // }
           

        }
        .code {
            flex: 1;
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;
            margin: 10px 150px;
            text-align: center;
                background: #FFFFFF;
                border-radius: 8px;
                img {
                    box-shadow: 0px -1px 16px 0px rgba(0, 0, 0, 0.11), 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
                    width: 150px;
                    border-radius: 8px;
                }  
                .Follow{
                    margin-top: 10px;
                }
            }  
        .modular {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            display: flex;
            flex-direction: column;
            .item{
                margin-bottom: 10px;
            }
          

            }

        }
    

  
}
</style>