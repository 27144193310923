import axios from 'axios';

const myAxios = axios.create({
    'Cache-Control': 'no-cache', // 不要缓存
    // baseURL: 'http://192.168.1.111:8080',
    baseURL: 'https://www.luda-tec.com/prod-api',
    timeout: 1000 * 30, // 30秒超时
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', // 
    // 'Content-Type': 'multipart/form-data',
    'Content-Type': "application/x-www-form-urlencoded",
    // ▼提交之前，将数据形式转化
    // transformRequest: [function (data /* , headers */ ) {
    //     // if (['put', 'post', 'patch'].includes(config.method)) {}
    //     if (!data) return data; // 没有，直接返回
    //     return dataToFormData(data);
    //     // return data;
    // }],
});

// ▼转formData
// function dataToFormData(oData) {
//     const oFormData = new FormData();
//     for (const [key, val] of Object.entries(oData)) {
//         if (Array.isArray(val)) { //是
//             for (const oneItem of val) oFormData.append(key, oneItem);
//         } else {
//             if (val !== null) {
//                 oFormData.append(key, val);
//             }
//         }
//     }
//     return oFormData;
// }

// ▼请求拦截器
myAxios.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
});

// ▼响应拦截器
myAxios.interceptors.response.use(response => {
    return response;
}, (oError) => {
    return Promise.reject(oError);
});

// myAxios.interceptors.response.use(
//     response => {
//       if (response.data.code != 0) {
//         return Promise.reject(response);
//       }
//       return response.data
//     }
//   )


export default myAxios;