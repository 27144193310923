// import "babel-polyfill"
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'reset-css'
import Element from 'element-ui';
import myAxios from '@/axios';
import 'element-ui/lib/theme-chalk/index.css';
// import 'lib-flexible/flexible.js'
import '@/assets/style/common.less'
import '@/assets/style/quill.bubble.css'
import '@/assets/style/quill.core.css'
import '@/assets/style/quill.snow.css'


Vue.config.productionTip = false

window.fnAxios = myAxios;
Vue.use(Element, { size: 'small', zIndex: 3000 });
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
