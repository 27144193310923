<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <footerVue></footerVue>
  </div>
  
</template>

<script>
import Header from '@/components/header/header.vue';
import footerVue from './components/footer/footer.vue';
export default {
  components: {
    Header,
    footerVue
  }
}
</script>
<style lang="less">
  /* body {
    position: relative;
  } */
  #app {
    /* position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%); */
    width: 100%;
    height: auto;
  }
</style>
